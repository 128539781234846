import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import { ISDCOrder } from "@samedaycustom/types/order/@types/order";
import * as api from "admin/helpers/constants";
import { ERefundType } from "admin/helpers/validation";
import { catchError } from "admin/providers/actions/app";
import request, { Canceler } from "axios";
import qs from "querystring";
import { Dispatch } from "redux";
import { OrderListPaginationInfo } from "types/app/Operations";
import { DELIVERY_TYPE } from "types/order/@types/order";
import { validate } from "uuid";
import { getVendorUnreadMesageCount } from "../../features/message/async";
import { updateVendorOrders, updateVendorOrdersMeta } from "../vendors";
import {
  FETCH_ORDER,
  FETCH_ORDER_LINEITEMS,
  FETCH_ORDERS,
  FETCH_ORDERS_META,
  FETCHING_ORDER,
  FETCHING_ORDER_LINEITEMS,
  FETCHING_ORDERS,
  FETCHING_ORDERS_ERROR,
  ORDERS_ACTION_TYPES,
  RESET_ORDER,
  RESET_ORDERS,
  UPDATE_DELIVERY_METHOD,
  UPDATE_ORDER_REFUND_AMOUNT,
  UPDATE_SINGLE_ORDER,
} from "./type";
import { getRecordRangeFromLocalStorage } from "admin/helpers/helpers";

const CancelToken = request.CancelToken;
let orders_cancel: Canceler;
let single_order_cancel: Canceler;
let single_order_line_cancel: Canceler;
export const updateOrders = (orders: ISDCOrder[], type?: "new") => {
  return async (dispatch: any, getState?: any) => {
    await dispatch({
      type: FETCH_ORDERS,
      payload: { orders, updateType: type },
    });

    return Promise.resolve(getState().orders);
  };
};

export const updateOrdersMetadata = (metadata: OrderListPaginationInfo) => {
  return async (dispatch: any, getState?: any) => {
    await dispatch({
      type: FETCH_ORDERS_META,
      payload: { metadata },
    });

    return Promise.resolve(getState().orders);
  };
};

export const resetOrders = () => ({
  type: RESET_ORDERS,
});
export const resetOrder = () => ({
  type: RESET_ORDER,
});
export const resetOrderState = () => ({
  type: RESET_ORDER,
});

export const fetchingOrders = (
  loading: boolean,
  fromVendorAccountInAdmin?: boolean
): ORDERS_ACTION_TYPES => ({
  type: FETCHING_ORDERS,
  payload: {
    loading,
    fromVendorAccountInAdmin: fromVendorAccountInAdmin ?? false,
  },
});
export const updateOrder = (order: ISDCOrder): ORDERS_ACTION_TYPES => ({
  type: FETCH_ORDER,
  payload: { order },
});
export const partlyUpdateOrder = (data: any) => ({ type: UPDATE_SINGLE_ORDER, payload: data });

export const updateDeliveryMethodOrder = (deliveryMethod: DELIVERY_TYPE): ORDERS_ACTION_TYPES => ({
  type: UPDATE_DELIVERY_METHOD,
  payload: { deliveryMethod },
});

export const updateOrderRefundAmount = ({
  totalRefunds,
  billableAmount,
  errorMessage,
}: {
  totalRefunds?: number;
  billableAmount?: number;
  errorMessage?: string;
}): ORDERS_ACTION_TYPES => ({
  type: UPDATE_ORDER_REFUND_AMOUNT,
  payload: { totalRefunds, billableAmount, errorMessage },
});

export const fetchingOrder = (loading: boolean): ORDERS_ACTION_TYPES => ({
  type: FETCHING_ORDER,
  payload: { loading },
});

export const updateOrderLineItems = (orderLineItems: ISDCLine[]): ORDERS_ACTION_TYPES => ({
  type: FETCH_ORDER_LINEITEMS,
  payload: { orderLineItems },
});

export const fetchingOrderItems = (loading: boolean): ORDERS_ACTION_TYPES => ({
  type: FETCHING_ORDER_LINEITEMS,
  payload: { loading },
});

/**
 *
 * get all orders
 */
export interface IParam {
  search?: string;
  cancelled?: boolean;
  location?: string;
  vendor?: string;
  topOrderDate?: Date | string | number;
  bottomOrderDate?: Date | string | number;
  filterParams?: string;
  limit?: number;
  status?: "unassigned" | "pending_cancellation";
  type?: "open" | "completed" | "awaiting" | "c_missing";
  cancelToken?: typeof CancelToken;
  top?: number;
  // The get order tab in the vendor profile within  the admin status
  fromVendorAccountInAdmin?: boolean;
  pf: string;
  pt: string;
  page: "next" | "previous";
  source: "webshop" | "network";
}

export const getOrders = (param: IParam = { pf: "", pt: "", page: "next" }) => {
  orders_cancel && orders_cancel("cancelled");
  return async (dispatch: any, getState: () => any) => {
    const auth = getState().auth;

    if (getRecordRangeFromLocalStorage("orders")) {
      param.limit = Number(getRecordRangeFromLocalStorage("orders"));
    }
    if (!param?.search && !param?.topOrderDate && !param?.bottomOrderDate) {
      await dispatch(resetOrders());
      dispatch(fetchingOrders(true));
    }

    const query = {};
    // Add BE required query params
    Object.assign(query, {
      pf: param?.pf,
    });

    Object.assign(query, {
      pt: param?.pt,
    });

    Object.assign(query, {
      page: param?.page,
    });

    // Other options query params
    if (param?.search) {
      Object.assign(query, {
        search: param?.search,
      });
    }
    if (param?.cancelled === true) Object.assign(query, { status: "CAN" });
    if (param?.status)
      Object.assign(query, {
        status: param.status,
      });
    if (param?.type)
      Object.assign(query, {
        type: param.type,
      });
    if (param?.location)
      Object.assign(query, {
        store: validate(param?.location) ? param?.location : "",
      });
    if (param?.vendor)
      Object.assign(query, {
        vendorID: param.vendor, // vendorID is the vendor's ID and should be deprecated
        vendor: param.vendor,
      });
    // set query parameter for order before date (pagination)
    if (param?.bottomOrderDate)
      Object.assign(query, {
        next: param.bottomOrderDate,
      });
    if (param?.topOrderDate)
      Object.assign(query, {
        prev: param.topOrderDate,
      });
    if (param?.limit)
      Object.assign(query, {
        limit: param.limit,
      });
    if (param?.source) {
      Object.assign(query, {
        source: param.source,
      });
    }

    return request
      .get(`${api.ORDERS}?${qs.stringify(query)}`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },

        cancelToken: new CancelToken((c) => {
          orders_cancel = c;
        }),
      })
      .then(async (result) => {
        if (result) {
          const data: ISDCOrder[] = result?.data?.data.orders ?? result?.data?.data.data;
          const meta: OrderListPaginationInfo = {
            all: result?.data?.data?.count || 0,
            completed: 0,
            open: 0,
            pf: result?.data?.data?.pf || "",
            pt: result?.data?.data?.pt || "",
            isFirstPage: result?.data?.data?.pageMeta?.isFirstPage || false,
            isLastPage: result?.data?.data?.pageMeta?.isLastPage || false,
            pageStart: result?.data?.data?.pageMeta?.start || 0,
            pageEnd: result?.data?.data?.pageMeta?.end || 0,
          };

          if (param.fromVendorAccountInAdmin) {
            await dispatch(updateVendorOrders(data));
            await dispatch(updateVendorOrdersMeta(meta));
          } else {
            await dispatch(updateOrders(data));
            await dispatch(updateOrdersMetadata(meta));
            await dispatch(getVendorUnreadMesageCount());
          }
          dispatch(fetchingOrders(false));
          return Promise.resolve(result);
        }

        return null;
      })
      .catch((err) => {
        if (err?.message !== "cancelled") {
          dispatch(fetchingOrders(false));
          dispatch(catchError(FETCHING_ORDERS_ERROR, err));
        }
        return;
      });
  };
};

/**
 *
 * get single order
 */
export const getOrder = (id: string, isSlient?: boolean) => {
  single_order_cancel && single_order_cancel("canncelled");
  return (dispatch: Dispatch, getState: () => any) => {
    const auth = getState().auth;

    !isSlient && dispatch(fetchingOrder(true));
    return request
      .get(`${api.ORDERS}/${id}`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },

        cancelToken: new CancelToken((c) => {
          single_order_cancel = c;
        }),
      })
      .then(async (result) => {
        if (result) {
          const data: ISDCOrder = result?.data?.data || result?.data;
          await dispatch(updateOrder(data));
          dispatch(fetchingOrder(false));
        }
      })
      .catch((err) => {
        if (err?.message !== "canncelled") {
          dispatch(fetchingOrder(false));
          return Promise.reject(err);
        }
        return null;
      });
  };
};
/**
 *
 * get single order line items
 */
export const getOrderLineItems = (id: string, isSlient?: boolean) => {
  single_order_line_cancel && single_order_line_cancel("canncelled");
  return (dispatch: Dispatch, getState: () => any) => {
    const auth = getState().auth;

    !isSlient && dispatch(fetchingOrderItems(true));
    return request
      .get(`${api.ORDERS}/${id}/lines`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
        cancelToken: new CancelToken((c) => {
          single_order_line_cancel = c;
        }),
      })
      .then(async (result) => {
        if (result) {
          const data: ISDCLine[] = result?.data?.data || result?.data;
          await dispatch(updateOrderLineItems(data));
          dispatch(fetchingOrderItems(false));
        }
      })
      .catch((err) => {
        if (err?.message !== "canncelled") {
          dispatch(fetchingOrderItems(false));
          return Promise.reject(err);
        }
        return null;
      });
  };
};

export const addOrderRefund = ({
  orderId,
  refundAmount,
  refundType,
  refundValue,
  refundReason,
}: {
  orderId: string;
  refundAmount: number;
  refundType: ERefundType;
  refundValue: number;
  refundReason: string;
}) => {
  // try {
  return async (dispatch: any, getState: any) => {
    try {
      const { token } = getState().auth;
      const response = await request.post(
        `${api.ORDERS}/${orderId}/refund`,
        {
          orderId,
          refundAmount: Number(refundAmount),
          refundType,
          refundValue: Number(refundValue),
          refundReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": api.CONTENT_TYPE,
          },
        }
      );
      if (response) {
        dispatch(updateOrderRefundAmount(response?.data));
      }
      return Promise.resolve(response);
    } catch (error) {
      if (typeof error === "string") {
      } else if (error instanceof Error) {
        // error.message; // works, `e` narrowed to Error
        const e: any = error;
        if (e.response.data?.error && e.response.data?.message) {
          dispatch(updateOrderRefundAmount({ errorMessage: e?.response.data?.message }));
        }
      }
      return Promise.reject(null);
    }
  };
};
